import { http } from '@/http/axios.js'
// list
export function getListAPI(params) {
  return http({
    url: '/company/basic/marketing/coupon/list',
    method: 'get',
    params
  })
}
// add
export function addCouponAPI(data) {
  return http({
    url: '/company/basic/marketing/coupon/add',
    method: 'post',
    data
  })
}
// edit
export function editCouponAPI(data) {
  return http({
    url: '/company/basic/marketing/coupon/edit',
    method: 'post',
    data
  })
}
// delete
export function deleteCouponAPI(id) {
  return http({
    url: '/company/basic/marketing/coupon/del',
    method: 'post',
    data: { id }
  })
}
// detail
export function getCouponDetailAPI(id) {
  return http({
    url: '/company/basic/marketing/coupon/detail',
    method: 'get',
    params: { id }
  })
}
