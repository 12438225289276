<template>
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>
        <el-form class="addForm" ref="form" :rules="couponRules" :model="formData" label-width="120px">
            <el-form-item label="优惠券名称" prop="name" :rules="rules">
                <el-input v-model="formData.name" size="small"></el-input>
            </el-form-item>
            <el-form-item label="优惠券别称" prop="alias_name" :rules="rules">
                <el-input v-model="formData.alias_name" size="small"></el-input>
            </el-form-item>
            <el-form-item label="优惠券小标题" prop="sub_name" :rules="rules">
                <el-input v-model="formData.sub_name" size="small"></el-input>
            </el-form-item>
            <el-form-item label="背景图" prop="name" :rules="rules">
                <file-upload-img :url.sync="formData.coupon_bg"></file-upload-img>
            </el-form-item>
            <el-form-item label="优惠券类型" prop="coupon_type" :rules="rules">
                <el-radio-group v-model="formData.coupon_type">
                    <el-radio :label="10">满减券</el-radio>
                    <el-radio :label="20">折扣券</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="减免金额" v-show="formData.coupon_type == 10" prop="reduce_price">
                <el-input style="width:100px;margin-right:10px;" v-model="formData.reduce_price"
                          size="small"></el-input>
                <span>元</span>
            </el-form-item>
            <el-form-item label="折扣率" v-show="formData.coupon_type == 20" prop="discount">
                <el-input style="width:100px;margin-right:10px;" v-model="formData.discount" size="small"></el-input>
                <div class="tips">折扣率范围 0-100，80代表打8折，0代表不折扣</div>
            </el-form-item>
            <el-form-item label="最低消费金额" prop="min_price" :rules="rules">
                <el-input style="width:100px;margin-right:10px;" v-model="formData.min_price" size="small"></el-input>
                <span>元</span>
            </el-form-item>
            <el-form-item style="margin-bottom:0;" label="到期类型" prop="expire_type" :rules="rules">
                <el-radio-group v-model="formData.expire_type">
                    <el-radio :label="10">领取后生效</el-radio>
                    <el-radio :label="20">固定时间</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="">
                <el-input v-show="formData.expire_type == 10" style="width:250px;" v-model.number="formData.expire_day"
                          size="small">
                    <template slot="prepend">有效期</template>
                    <template slot="append">天</template>
                </el-input>
                <el-date-picker
                    style="width:350px;"
                    v-show="formData.expire_type == 20"
                    v-model="formData.between_time"
                    size="small"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item style="margin-bottom:0;" label="券适用范围" prop="apply_range" :rules="rules">
                <el-radio-group v-model="formData.apply_range">
                    <el-radio :label="10">全场通用</el-radio>
                    <!-- <el-radio :label="20">指定商品</el-radio>
                    <el-radio :label="30">排除商品</el-radio> -->
                </el-radio-group>
            </el-form-item>
            <el-form-item label="" v-show="formData.apply_range != 10">
                <el-button type="primary" size="small" plain @click="selectGoods">选择商品</el-button>
            </el-form-item>
            <el-form-item label="发放总数量" prop="total_num" :rules="rules">
                <el-input style="width:100px;margin-right:10px;" v-model.number="formData.total_num"
                          size="small"></el-input>
                <span>张</span>
                <div class="tips">发放的优惠券总数量，-1为不限制</div>
            </el-form-item>
            <el-form-item label="显示状态" prop="status" :rules="rules">
                <el-radio-group v-model="formData.status">
                    <el-radio :label="1">显示</el-radio>
                    <el-radio :label="0">隐藏</el-radio>
                </el-radio-group>
                <div class="tips">如果设为隐藏将不会展示在用户端页面</div>
            </el-form-item>
            <el-form-item label="优惠券描述">
                <el-input style="width:400px" rows="3" type="textarea" v-model="formData.describe"></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="sort" :rules="rules">
                <el-input style="width:100px;" size="small" v-model.number="formData.sort"></el-input>
                <div class="tips">数字越小越靠前</div>
            </el-form-item>
            <el-form-item label="">
                <el-button type="primary" size="small" @click="save">保存</el-button>
                <el-button type="" size="small" @click="$router.back()">取消</el-button>
            </el-form-item>
        </el-form>
        <div style="height:50px;"></div>
        <!--  -->
        <el-dialog title="商品库" :visible.sync="dialogVisible" width="width">
            <div class="header-search">
                <el-form ref="form" inline :model="searchData" label-width="">
                    <el-form-item label="商品名称">
                        <el-input v-model="searchData.nickName" size="small" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="商品分类">
                        <el-input v-model="searchData.nickName" size="small" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <tp-table
                @handleSelectionChange="handleSelectionChange"
                :isCheckBox="true"
                :tableData="list"
                :columns="columns"
                :totalNum="total"
                :current-page.sync="currentPage"
                :pageSize.sync="page_size"
            />
            <div slot="footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {editCouponAPI, addCouponAPI, getCouponDetailAPI} from './api'
import FileUploadImg from "@/components/uploader/uploader-img.vue";

const columns = [
    {
        label: '商品ID',
        prop: 'id',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['id']}</p>
        }
    },
    {
        label: '商品信息',
        prop: 'nickName',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['nickName']}</p>
        }
    },
    {
        label: '商品价格',
        prop: 'memberName',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['memberName']}</p>
        }
    },
    {
        label: '库存总量',
        prop: 'mobile',
        minWidth: '150',
        customRender(h, row) {
            return <p>{row['mobile']}</p>
        }
    },
    {
        label: '状态',
        prop: 'identityNo',
        minWidth: '150',
        customRender(h, row) {
            return <p>{row['identityNo']}</p>
        }
    }
]
export default {
    name: 'AddOrEdit',
    components: {FileUploadImg},

    data() {
        var validateReduce = (rule, value, callback) => {
            if (this.formData.coupon_type == 10) {
                if (value) {
                    callback()
                } else {
                    callback(new Error('该项不能为空'))
                }
            }
        }
        var validateDis = (rule, value, callback) => {
            if (this.formData.coupon_type == 20) {
                if (value) {
                    callback()
                    console.log(value)
                } else {
                    callback(new Error('该项不能为空'))
                }
            }
        }
        return {
            rules: [{required: true, message: '该项不能为空', trigger: blur}],
            formData: {
                coupon_type: 10,
                reduce_price: '', //减免金额
                discount: '', //折扣率
                coupon_bg: '', //折扣率
                expire_type: 10,
                between_time: [],
                expire_day: 0,
                apply_range: 10,
                name: '',
                sub_name: '',
                alias_name: '',
                min_price: '',
                total_num: 0,
                status: 1,
                describe: '',
                sort: 0
            },
            dialogVisible: false,
            columns,
            currentPage: 1,
            page_size: 5,
            total: 0,
            list: [{id: 111}, {id: 222}],
            searchData: {},
            couponRules: {
                // reduce_price: [{ validator: validateReduce, trigger: 'blur' }],
                // discount: [{ validator: validateDis, trigger: 'blur' }]
            }
        }
    },

    mounted() {
        if (this.$route.params.id) this.getCouponDetail()
    },

    methods: {
        save() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    if (this.formData.id) {
                        editCouponAPI(this.formData).then(() => {
                            this.$message.success('编辑成功')
                            this.$router.back()
                        })
                    } else {
                        addCouponAPI(this.formData).then(() => {
                            this.$message.success('添加成功')
                            this.$router.back()
                        })
                    }
                }
            })
        },
        async getCouponDetail() {
            this.formData = await getCouponDetailAPI(this.$route.params.id)
        },
        selectGoods() {
            this.dialogVisible = true
        },
        handleSelectionChange(val) {
            console.log(val)
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;

  .addForm {
    .el-form-item {
      .el-input {
        width: 400px;
      }

      .tips {
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
        line-height: 30px;
      }

      .el-range-separator {
        padding: 0;
      }
    }
  }

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 10px;

        .el-input {
          width: 250px;
        }

        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }

          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }
}
</style>
